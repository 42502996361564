import React, { lazy } from "react"
import Helmet from "react-helmet"
import  withPrefix from "gatsby"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Layout from "../components/layout"
import '../styles/home.css'
import imghome from "../images/intro-vector.png" // Tell webpack this JS file uses this image
import farmincover from "../images/farmin-cover.png" // Tell webpack this JS file uses this image
import rccover from "../images/rc-cover.png" // Tell webpack this JS file uses this image
import fpacover from "../images/fpa-cover.png" // Tell webpack this JS file uses this image
import $ from 'jquery/dist/jquery'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from 'gatsby'
import ProjectPreview from '../components/project-preview'
import '../styles/styles.css'
import '../styles/utilitary.css'
import TextComponent from '../components/TextComponent'

const IndexPage = () => {     
  const data= useStaticQuery(graphql`
    {
      allProjectsJson(filter: {homepage: {eq: "true"}}){
        edges{
          node{
            title
            ano
            description
            slug
            imagecover{
              childImageSharp{
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)  


  const projects = data.allProjectsJson.edges;
  return (
    <Layout>
        <Helmet>
    <script src="script.js" type="text/javascript" />
    </Helmet>
    <SEO
       
       pagePath="/"
       schema={`{
             "@context": "http://schema.org",
             "@type": "WebPage",
             "mainEntity": {
               "@type": "Organization",
               "name": "Forty Degrees - Creative Studio",
               "image": "/img/logo.png"
             }
           }`}
     />
    
      <div id="conteudo">
        <div id="wrapper-conteudo">
            <div class="col col-50 vertical">
              <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="hi-people ">
                  <span class="gt-light spanfirst">People first </span><span class="emoji">👋</span>
                  
              </div>
              <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="intro">
                  <h1 class="gt-light">
                    Forty Degrees is a digital design studio <br></br>focused on the development of digital products <br></br> and successful branding.
                  </h1>
              </div>
            </div>
            <div class="col col-50 vertical">
              <img data-sal="slide-up" data-sal-delay="800" data-sal-duration="800" data-sal-easing="ease" id="logo-desktop" src={imghome} class="intro-vector" alt="forty degrees" />
            </div>
        </div>
      </div>
      <div id="second-section">

        <div id="wrapper-second">
        {projects.map(({node: project }) => {
        const title = project.title;
        const slug = project.slug;
        const imagecover = project.imagecover.childImageSharp.fluid;
        const ano = project.ano;
        const description = project.description;



        return(
          <ProjectPreview
          title={title}
          slug={slug}
          imagecover={imagecover}
          ano={ano}
          description={description}
          />

      );
        })}
           
          
          
        
      
       
        </div>
        <div id="more-projects">
        <Link to="/work" style={{ textDecoration: 'none' }}> <span class="menu-label gt-regular blue">More work &rarr;</span></Link>

        </div>
    </div>
        
    </Layout>
)
}

export default IndexPage

import React from 'react';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import '../styles/projects-view.css'
import '../styles/utilitary.css'



const ProjectPreview = ({ slug, title, imagecover, ano, description }) => (
    
   
        
            <div class="col col-50">
                <div class="project-preview">
                    <div class="imagem-capa">
                    <div class="onlyimg">
                    <Link to={`work/${slug}/`}>


                        <Image fluid={imagecover} alt={title}  />
                    </Link>
                    </div>
                    <p class="gt-light titulo-project">{title}</p>
                    <p class="gt-light ano-project">{ano}</p>
                    </div>

                </div>
            </div>
        
    
    
  
)
export default ProjectPreview